
import { useRef, useEffect, useState } from 'react';
import './App.css';
import {
  useLocation,
  withRouter,
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import JSZip from 'jszip';
import MarkdownIt from 'markdown-it';
import { images } from './images';
import anime from "animejs";

const MD = new MarkdownIt({ html: true });

// - using for titles
// https://tobiasahlin.com/moving-letters/#16
// https://tobiasahlin.com/moving-letters/#6
// https://tobiasahlin.com/moving-letters/#7

// - watch elements visible:
// https://24ways.org/2019/beautiful-scrolling-experiences-without-libraries/

// - nav menu animation hide and show and choice

// - use nice google web fonts

// - use target=_blank for mardkown links
// - if a word is all caps, capitalise it (and in future maybe embolden it)

const sections = [{
  name: 'People',
  path: '/',
  slides: [1, 3, 7, 9, 17, 18, 19, 26, 49, 51, 52, 68, 87, 101, 102, 120, 123, 142, 143, 146, 150, 156, 157, 158, 159, 160, 166, 167, 170, 173, 174, 182]
},{
  name: 'Science',
  path: '/science',
  slides: [2, 5, 8, 24, 28, 29, 30, 31, 32, 33, 34, 35, 36, 39, 41, 42, 43, 44, 45, 46, 47, 48, 53, 54, 55, 56, 57, 59, 60, 64, 65, 66, 67, 78, 79, 80, 81, 82, 83, 84, 90, 91, 97, 119, 121, 149, 155]
},{
  name: 'Purpose',
  path: '/purpose',
  // 58 at top - pangea
  slides: [58, 22, 25, 38, 40, 50, 70, 71, 93, 94, 98, 100, 104, 105, 106, 107, 108, 109, 111, 112, 113, 114, 115, 116, 117, 122, 127, 128, 129, 130, 131, 132, 133, 134, 135, 138, 148, 151, 153, 154, 163, 164, 165, 171, 176, 177, 179, 180, 183, 185]
},{
  name: 'Other',
  path: '/other',
  slides: [4, 6, 10, 11, 12, 13, 14, 15, 16, 20, 21, 23, 27, 37, 61, 62, 63, 69, 72, 73, 74, 75, 76, 77, 85, 86, 88, 89, 92, 95, 96, 99, 103, 110, 118, 124, 125, 136, 137, 139, 140, 141, 147, 152, 161, 162, 169, 172, 175, 178, 181, 184]
},{
  name: 'About',
  path: '/about',
  slides: ['_intro']
}];

function useOnScreen(ref) {

  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting),
    {
      threshold: 0.15
    }
  );

  useEffect(() => {
    observer.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect() }
  }, [])

  return isIntersecting
}

function capitalize(v) {
  return v[0].toUpperCase() + v.substring(1).toLowerCase();
}

function isUpper(s) {
  return s.toUpperCase() === s;
}

function updateMarkdownHtml(html) {
  return html
    .replace(/\s\?/, '?')
    .replace(/\b(\w+)\b/ig, (v) => {
      // set all uppercase just to capitalised
      if (isUpper(v) && !["I", "VIII", "DNA", "USA"].includes(v)) {
        return capitalize(v);
      }
      return v;
    })
    .replace(/<code([^>]*)>([^<]+)<\/code>/ig, function(orig, match1, match2) {
      // bold within quotes
      if (match2.indexOf("**") >= 0) {
        return '<code>' + MD.render(match2).replace(/<\/?p>/ig, '') + '</code>';
      }
      return orig;
    })
    .replace(/<pre/ig, '<div class="quote-container"')
    .replace(/<\/pre/ig, '</div')
    .replace(/<code/ig, '<p')
    .replace(/<\/code/ig, '</p')
    .replace(/<a([^>]*)>YouTube Video<\/a>/g, (v, cap1) => `<a ${cap1} style="text-decoration-color:#f5da42;font-size:2rem;font-weight:bold;color:#f5da42;font-family:\'Merriweather\',Serif">YouTube Video</a>`)
    .replace(/<a/ig, '<a target="_blank" rel="noopener noreferrer"')
}

function loadImage(url) {
  return new  Promise(resolve => {
      const image = new Image();
      image.addEventListener('load', () => {
          resolve(image);
      });
      image.src = url; 
  });
}

function getIndices(re, str, idx=0) {
  let out = [];
  let match = null;
  while ((match = re.exec(str)) != null) {
    out.push(match.index + idx);
  }
  return out;
}

function makeImage(image, idx) {
  return `<div class="im-holder ${idx % 2 === 0 ? 'left' : 'right'}"><img loading="lazy" src="data/PyMuPDF/${image}" /></div>`
}

function insertImages(str, indices, images, count) {
  let len1 = indices.length;
  let imCount = 0;

  while (len1--) {
    let idx = indices[len1];

    let imStr = "";
    for (let i = 0; i < count; i++) {
      let image = images[imCount];
      if (images[imCount + 1] === undefined) {
        break;
      }
      imStr += makeImage(image, imCount);
      imCount++;
    }
    str = `${str.slice(0, idx)}${imStr}${str.slice(idx)}`;
  }
  // always put animage at end
  return str + (images.length ? makeImage(images[images.length - 1], imCount) : '');
}

function Slide({ id, md, images=[] }) {

 const ref = useRef();
 const isVisible = useOnScreen(ref);
 const [finalMD, setFinalMD] = useState("");
 const [titleCount, setTitleCount] = useState(0);

  useEffect(() => {

    // preload images
    Promise.all(images.map(path => loadImage(`data/PyMuPDF/${path}`)))

    let out;
    if (images.length && md && md.length) {
      const titleEnds = getIndices(/\/h\d\>/ig, md, 4);
      const imagesPerPlace = Math.ceil(images.length / titleEnds.length);
      out = insertImages(md, titleEnds, images, imagesPerPlace);
    } else {
      out = md;
    }

    let count = 0;
    out = out.replace(/(<h\d)>([^<]*)(\<\/h\d>)/ig,
      (m, c1, c2, c3) => {
        count++;
        let letters = c2.replace(/&amp;/, '&').replace(/[^\s]+/g, (v) => `<span class='letter'>${v}&nbsp;</span>`);
        return `${c1} class="ml16 title-anim-${count}">${letters}${c3}`;
      });
    setTitleCount(count);

    setFinalMD(out);
  }, [md, images]);

  useEffect(() => {
    if (isVisible && ref.current && titleCount) {
      for (var i = 0; i < titleCount; i++) {
        anime.timeline({loop: false})
          .add({
            targets: `#${id} .title-anim-${i + 1} .letter`,
            translateY: [70,0],
            opacity: [0, 1],
            easing: "easeOutExpo",
            duration: 1400,
            delay: (el, idx) => (120 * idx)
          })
      }
    }
  }, [isVisible, ref, titleCount])

  return <div
    ref={ref}
    id={id}
    className={`slide ${isVisible ? '' : 'hidden'}`}>
    <div dangerouslySetInnerHTML={{ __html: finalMD }}></div>
  </div>
}

function Slides({ lookup, slides }) {

  if (!lookup) {
    return <h2 style={{ margin: 'auto', paddingTop: '10rem', textAlign: 'center' }}>
      <div>Loading...</div>
      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>      
    </h2>
  }

  return <div className="container">
      <div className="wrap" id="wrap-item">
        {
          slides.map((id) => {
            const key = `markdown/${id}.md`;
            return <Slide
              id={"slide-" + id}
              key={key}
              md={lookup[key]}
              images={images[`${typeof id === 'number' ? (id - 1) : id}`]} />
          })
        }
      </div>
    </div>
}

function _Nav() {
  const location = useLocation();

  return <nav>
  {
    sections.map((obj) =>
      <Link className={location.pathname === obj.path ? "active" : ""} key={obj.path} to={obj.path}><div>{ obj.name }</div></Link>
    )
  }
</nav>
}

const Nav = withRouter(_Nav);

function App() {

  const [lookup, setLookup] = useState();

  useEffect(() => {
    fetch('data/markdown.zip')
      .then(function (response) {
          if (response.status === 200 || response.status === 0) {
              return Promise.resolve(response.blob());
          } else {
              return Promise.reject(new Error(response.statusText));
          }
      })
      .then(JSZip.loadAsync)
      .then(function (zip) {
        const files = Object.keys(zip.files).filter(n => {
          if (!!n.match(/\.md$/)) {
            return true;
          }
        });
        return Promise.all(files.map((f) =>
          zip.files[f].async("string")
            .then((d) => [f, updateMarkdownHtml(MD.render(d))])
        ))
      })
      .then((obj) => {
        let _lookup = {};
        obj.forEach(([k, v]) => {
          _lookup[k] = v;
        });
        setLookup(_lookup);
      });

  }, [])

  return (
    <Router>
    <div className="wrapper">
      <Nav />
      <Switch>
        {
          sections.map((section) => <Route key={section.path} exact path={section.path}>
            { <Slides lookup={lookup} slides={section.slides} /> }
          </Route>)
        }
      </Switch>
    </div>
    </Router>
  );
}

export default App;
