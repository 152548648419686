
// from `ls -l`
export const images = `p_intro-1.jpg
p0-17.jpg
p0-8.jpg
p1-26.jpg
p1-28.jpg
p10-153.jpg
p10-155.jpg
p100-1538.jpg
p101-1547.jpg
p101-1549.jpg
p102-1583.jpg
p103-1595.jpg
p103-1597.jpg
p104-1597.jpg
p104-1613.jpg
p105-1629.jpg
p105-1631.jpg
p106-1813.jpg
p107-1813.jpg
p108-1833.jpg
p109-1848.jpg
p11-162.jpg
p11-164.jpg
p110-1861.jpg
p111-1868.jpg
p112-1876.jpg
p113-1885.jpg
p114-1897.jpg
p115-1909.jpg
p116-1919.jpg
p116-1921.jpg
p117-1928.jpg
p118-1937.jpg
p118-1939.jpg
p118-1941.jpg
p119-1951.jpg
p119-1953.jpg
p12-171.jpg
p12-173.jpg
p12-175.jpg
p120-1961.jpg
p121-1968.jpg
p122-1978.jpg
p123-1985.jpg
p123-1987.jpg
p123-1989.jpg
p123-1991.jpg
p124-1998.jpg
p124-2000.jpg
p125-2016.jpg
p125-2018.jpg
p125-2020.jpg
p126-2046.jpg
p126-2048.jpg
p126-2050.jpg
p126-2052.jpg
p127-2063.jpg
p128-2063.jpg
p129-2085.jpg
p13-183.jpg
p13-185.jpg
p130-2092.jpg
p131-2092.jpg
p132-2112.jpg
p132-2114.jpg
p133-2112.jpg
p133-2121.jpg
p134-2135.jpg
p135-2142.jpg
p136-2150.jpg
p136-2152.jpg
p137-2163.jpg
p138-2175.jpg
p138-2177.jpg
p138-2179.jpg
p138-2181.jpg
p138-2183.jpg
p138-2185.jpg
p138-2187.jpg
p138-2189.jpg
p139-2150.jpg
p139-2206.jpg
p139-2208.jpg
p14-192.jpg
p14-194.jpg
p14-196.jpg
p140-2219.jpg
p140-2221.jpg
p141-2228.jpg
p141-2230.jpg
p142-2257.jpg
p142-2259.jpg
p143-2277.jpg
p143-2279.jpg
p144-2292.jpg
p144-2294.jpg
p145-2312.jpg
p145-2314.jpg
p146-2333.jpg
p146-2335.jpg
p147-2344.jpg
p147-2346.jpg
p148-2344.jpg
p148-2346.jpg
p148-2355.jpg
p148-2357.jpg
p148-2359.jpg
p148-2361.jpg
p149-2390.jpg
p149-2392.jpg
p149-2394.jpg
p15-211.jpg
p15-213.jpg
p15-215.jpg
p15-217.jpg
p150-2401.jpg
p150-2403.jpg
p151-2410.jpg
p151-2412.jpg
p152-2425.jpg
p153-2443.jpg
p153-2445.jpg
p154-2458.jpg
p155-2469.jpg
p155-2471.jpg
p156-2478.jpg
p157-2491.jpg
p157-2493.jpg
p158-2511.jpg
p159-2523.jpg
p16-225.jpg
p16-227.jpg
p16-229.jpg
p160-2545.jpg
p161-2558.jpg
p161-2560.jpg
p161-2562.jpg
p162-2584.jpg
p163-2598.jpg
p163-2600.jpg
p163-2602.jpg
p163-2604.jpg
p164-2631.jpg
p164-2633.jpg
p165-2654.jpg
p165-2656.jpg
p166-2663.jpg
p167-2663.jpg
p168-2696.jpg
p168-2698.jpg
p168-2700.jpg
p168-2702.jpg
p169-2717.jpg
p17-239.jpg
p17-241.jpg
p17-243.jpg
p17-245.jpg
p17-247.jpg
p171-2560.jpg
p171-2732.jpg
p172-2746.jpg
p173-2753.jpg
p173-2755.jpg
p174-2208.jpg
p174-2770.jpg
p175-2777.jpg
p176-2785.jpg
p177-2793.jpg
p177-2795.jpg
p178-2802.jpg
p178-2804.jpg
p179-2811.jpg
p179-2813.jpg
p18-279.jpg
p180-2822.jpg
p180-2824.jpg
p181-2834.jpg
p181-2836.jpg
p182-2852.jpg
p183-2862.jpg
p183-2864.jpg
p183-2866.jpg
p183-2868.jpg
p183-2870.jpg
p184-2895.jpg
p184-2897.jpg
p19-291.jpg
p19-293.jpg
p19-295.jpg
p2-37.jpg
p2-39.jpg
p20-307.jpg
p20-309.jpg
p20-311.jpg
p20-313.jpg
p21-320.jpg
p21-322.jpg
p21-324.jpg
p22-343.jpg
p22-345.jpg
p22-347.jpg
p22-349.jpg
p23-376.jpg
p24-390.jpg
p25-397.jpg
p26-410.jpg
p26-413.jpg
p27-429.jpg
p27-431.jpg
p28-448.jpg
p28-450.jpg
p29-466.jpg
p29-468.jpg
p29-470.jpg
p3-48.jpg
p3-50.jpg
p30-480.jpg
p30-482.jpg
p31-496.jpg
p32-510.jpg
p32-512.jpg
p33-521.jpg
p33-523.jpg
p34-559.jpg
p34-561.jpg
p35-578.jpg
p35-580.jpg
p36-597.jpg
p36-599.jpg
p37-606.jpg
p38-616.jpg
p39-623.jpg
p39-625.jpg
p4-57.jpg
p40-633.jpg
p40-635.jpg
p41-647.jpg
p41-649.jpg
p42-658.jpg
p43-482.jpg
p43-672.jpg
p43-673.jpg
p44-685.jpg
p45-685.jpg
p46-685.jpg
p47-730.jpg
p48-738.jpg
p49-745.jpg
p49-747.jpg
p49-749.jpg
p5-64.jpg
p5-66.jpg
p50-756.jpg
p50-758.jpg
p50-760.jpg
p51-767.jpg
p52-777.jpg
p53-788.jpg
p53-792.jpg
p54-799.jpg
p54-801.jpg
p54-803.jpg
p54-805.jpg
p55-830.jpg
p56-843.jpg
p57-853.jpg
p58-862.jpg
p58-864.jpg
p58-866.jpg
p59-882.jpg
p59-884.jpg
p59-886.jpg
p59-888.jpg
p6-73.jpg
p60-896.jpg
p60-898.jpg
p61-909.jpg
p61-911.jpg
p61-913.jpg
p61-915.jpg
p62-943.jpg
p63-955.jpg
p63-957.jpg
p64-978.jpg
p65-993.jpg
p65-995.jpg
p65-997.jpg
p65-999.jpg
p66-1006.jpg
p66-1008.jpg
p66-1010.jpg
p66-1012.jpg
p66-1014.jpg
p66-1016.jpg
p67-1023.jpg
p67-1025.jpg
p68-1037.jpg
p68-1039.jpg
p69-1046.jpg
p7-82.jpg
p7-84.jpg
p70-1057.jpg
p71-1072.jpg
p71-1074.jpg
p71-1076.jpg
p71-1078.jpg
p72-1091.jpg
p72-1093.jpg
p73-1102.jpg
p73-1104.jpg
p73-1106.jpg
p74-1117.jpg
p74-1119.jpg
p74-1121.jpg
p74-1123.jpg
p75-1106.jpg
p76-1145.jpg
p76-1147.jpg
p77-1158.jpg
p77-1160.jpg
p77-1162.jpg
p77-1164.jpg
p78-1192.jpg
p78-1194.jpg
p79-1194.jpg
p79-1211.jpg
p79-1213.jpg
p8-101.jpg
p8-103.jpg
p8-97.jpg
p8-99.jpg
p80-1234.jpg
p80-1236.jpg
p81-1194.jpg
p81-1246.jpg
p82-1267.jpg
p82-1269.jpg
p83-1280.jpg
p84-1289.jpg
p84-1291.jpg
p84-1293.jpg
p84-1295.jpg
p84-1297.jpg
p85-1319.jpg
p85-1321.jpg
p85-1323.jpg
p86-1340.jpg
p86-1342.jpg
p87-1321.jpg
p87-1351.jpg
p88-1361.jpg
p89-1375.jpg
p9-112.jpg
p9-114.jpg
p9-116.jpg
p9-118.jpg
p9-120.jpg
p90-1391.jpg
p90-1393.jpg
p91-1413.jpg
p92-1420.jpg
p93-1427.jpg
p94-1437.jpg
p94-1439.jpg
p95-1476.jpg
p95-1478.jpg
p95-1480.jpg
p96-1503.jpg
p97-1515.jpg
p98-1524.jpg
p99-1531.jpg`.split('\n')
    .reduce((obj, img) => {
        const slide = img.split('-')[0].substring(1);
        if (!(slide in obj)) {
            obj[slide] = [];
        }
        obj[slide].push(img);
        return obj;
    }, {});